<template>
  <div id="welcomePage">
    <el-dialog
      :title="welcomePageFormTitle"
      width="450px"
      :visible.sync="welcomePageDialogVisible"
      :close-on-click-modal="false"
      @close="welcomePageDialogClose"
    >
      <el-form
        ref="welcomePageFormRef"
        :model="welcomePageForm"
        :rules="welcomePageFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="图片" prop="pic">
              <el-upload
                ref="uploadRef"
                action=""
                list-type="picture-card"
                :http-request="handleHttp"
                :on-remove="handleRemove"
                :on-preview="handlePreview"
                :file-list="fileList"
                :limit="1"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="welcomePageForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="官方活动id" prop="officialActivitiesId">
              <el-input v-model="welcomePageForm.officialActivitiesId" placeholder="请输入官方活动id" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="welcomePageDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="welcomePageFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog id="image" width="540px" :visible.sync="imageDialogVisible">
      <img
        width="500px"
        object-fit="contain"
        :src="imageUrl"
        alt=""
      >
    </el-dialog>
    <el-form inline size="small">
      <el-form-item>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="welcomePagePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="pic" label="图片">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.pic"
            style="width: 100px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.pic"
            :preview-src-list="[baseUrl + scope.row.pic]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="officialActivitiesId" label="官方活动id" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="welcomePagePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addWelcomePage,
  deleteWelcomePage,
  updateWelcomePage,
  selectWelcomePageInfo,
  selectWelcomePageList
} from '@/api/main/welcomePage'
import { upload2 } from '@/api/main/file'

export default {
  data () {
    return {
      welcomePageDialogVisible: false,
      welcomePageFormTitle: '',
      welcomePageForm: {
        id: '',
        pic: '',
        remarks: '',
        officialActivitiesId: ''
      },
      welcomePageFormRules: {
      },
      welcomePagePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10
      },
      baseUrl: 'https://oss-quye.huahuihongxin.com/',
      imageDialogVisible: false,
      imageUrl: '',
      fileList: []
    }
  },
  created () {
    selectWelcomePageList(this.searchForm).then(res => {
      this.welcomePagePage = res
    })
  },
  methods: {
    welcomePageDialogClose () {
      this.$refs.welcomePageFormRef.resetFields()
      this.fileList = []
    },
    welcomePageFormSubmit () {
      if (this.welcomePageFormTitle === '欢迎页详情') {
        this.welcomePageDialogVisible = false
        return
      }
      this.$refs.welcomePageFormRef.validate(async valid => {
        if (valid) {
          this.welcomePageForm.pic = this.fileList.map(item => item.name).join(',')
          if (this.welcomePageFormTitle === '新增欢迎页') {
            await addWelcomePage(this.welcomePageForm)
          } else if (this.welcomePageFormTitle === '修改欢迎页') {
            await updateWelcomePage(this.welcomePageForm)
          }
          this.welcomePagePage = await selectWelcomePageList(this.searchForm)
          this.welcomePageDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.welcomePageFormTitle = '新增欢迎页'
      this.welcomePageDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteWelcomePage(row.id)
        if (this.welcomePagePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.welcomePagePage = await selectWelcomePageList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.welcomePageFormTitle = '修改欢迎页'
      this.welcomePageDialogVisible = true
      this.selectWelcomePageInfoById(row.id)
    },
    handleInfo (index, row) {
      this.welcomePageFormTitle = '欢迎页详情'
      this.welcomePageDialogVisible = true
      this.selectWelcomePageInfoById(row.id)
    },
    selectWelcomePageInfoById (id) {
      selectWelcomePageInfo(id).then(res => {
        this.welcomePageForm.id = res.id
        if (res.pic) {
          const picUrl = res.pic.split(',')
          this.fileList = []
          for (let i = 0; i < picUrl.length; i++) {
            this.fileList.push({
              name: picUrl[i],
              url: this.baseUrl + picUrl[i]
            })
          }
        }
        this.welcomePageForm.remarks = res.remarks
        this.welcomePageForm.officialActivitiesId = res.officialActivitiesId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectWelcomePageList(this.searchForm).then(res => {
        this.welcomePagePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectWelcomePageList(this.searchForm).then(res => {
        this.welcomePagePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectWelcomePageList(this.searchForm).then(res => {
        this.welcomePagePage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload2(data).then(res => {
        this.fileList.push({
          name: res,
          url: this.baseUrl + res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file) {
      this.imageUrl = file.url
      this.imageDialogVisible = true
    }
  }
}
</script>

<style>
#welcomePage #image .el-dialog__body {
  padding: 20px;
}
</style>
